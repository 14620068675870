<template>
  <div class="index" @dblclick="screen">
    <dv-loading v-if="!loaded">Loading...</dv-loading>
    <dv-full-screen-container class="big">
      <div class="top">
        <Header />
      </div>
      <div class="bottom">
        <div class="bottom-main">
          <Bottom/>
        </div>
      </div>
    </dv-full-screen-container>
    <button style="position: fixed; left: 0; top: 0; z-index: 1000; display: none;" @click="screen">全屏</button>
  </div>
</template>

<script>

import Header from "../components/header";
import Bottom from "../components/bottom";
import {getTaskData} from "../api";

export default {
  name: 'App',
  components: {Bottom, Header},
  data(){
    return {
      loaded: false,
      fullscreen: false,
      data: {}
    }
  },
  mounted() {
      this.init()
  },
  methods: {
    init(){
      getTaskData().then(res => {
        this.loaded = true
        if(res.status === 1){
          this.$store.commit('user/SET_DATA', res.data)
          this.$forceUpdate()
          setTimeout(() => {
            this.init();
          }, 60000)
        }
      })
    },
    screen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
      setTimeout(() => {
        this.loaded = false
      }, 300)
      setTimeout(() => {
        this.loaded = true
      }, 500)
    },
  }
}
</script>

<style lang="scss">
@import "../assets/font/stylesheet.css";
.num{
  font-family: digital-7regular;
  font-size: 50px;
  color: #fff;
}
html, body, #app{
  width: 100%;
  height: 100%;
}
body{
  margin: 0;
  background: url("../assets/page_bg.png") #010f34;
  background-size: 100% 100%;
}
.dv-scroll-board{
  .header-item{
    color: #06B2F1;
    font-weight: bold;
  }
}
</style>
<style lang="scss">
.index{
  width: 100%;
  height: 100%;
}
.big{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  flex: 1;
  .top{
    height: 113px;
    width: 100%;
  }
  .bottom{
    height: calc(100% - 166px);
    width: calc(100%);
    position: relative;
    .bottom-main{
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100% - 70px);
      height: 100%;
      padding: 35px;
    }
  }
}
</style>
