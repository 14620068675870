<template>
  <div class="bottom-right-1">
    <BorderBoxView1 title="护理服务">
      <div class="box-top">
        <XidiItem2 :list="xidiValue1"></XidiItem2>
        <XidiItem2 :list="xidiValue2"></XidiItem2>
      </div>
      <div class="box-bottom">
        <dv-scroll-board :config="config" />
      </div>
    </BorderBoxView1>
  </div>
</template>

<script>
import BorderBoxView1 from "./border/borderBoxView1";
import XidiItem2 from "./xidiItem2";
import {mapState} from "vuex";
import {assign} from "xe-utils";
export default {
  name: 'BottomRight1',
  components: {BorderBoxView1, XidiItem2},
  computed: {
    ...mapState({
      data: function(state) {
        return state.user.data
      }
    }),
  },
  watch:{
    data (newValue){
      if(newValue){
        let data = newValue
        if(data.norsing){
          let config = assign(true, this.config)
          config.data = []
          data.norsing.list.map((res) => {
            config.data.push([res.name, res.address, res.bed_num, res.created_at])
          })
          this.config = config
          let xidiValue1 = JSON.parse(JSON.stringify(this.xidiValue1))
          let xidiValue2 = JSON.parse(JSON.stringify(this.xidiValue2))

          xidiValue1[0].value = parseInt(data.norsing.today_count)

          xidiValue2[0].value = parseInt(data.norsing.count)

          this.xidiValue1 = xidiValue1
          this.xidiValue2 = xidiValue2
        }
      }
    }
  },
  data() {
    return {
      config: {
        header: ['护理人员姓名', '服务位置', '床号', '服务时间'],
        data: [],
        align: ['center','center','center','center'],
        headerBGC: 'rgba(241,241,241,0.06)',
        oddRowBGC: 'rgba(241,241,241,0.06)',
        evenRowBGC: 'rgba(241,241,241,0)',
        rowNum: 2
      },
      xidiValue1: [
        {
          label: '今日护理服务工单',
          value: '23单',
          icon: require('../assets/icon0.png'),
          valueColor: '#E07EBC'
        }
      ],
      xidiValue2: [
        {
          label: '累计护理服务工单',
          value: '998单',
          icon: require('../assets/icon0.png'),
          valueColor: '#E07EBC'
        }
      ],
    }
  }
}
</script>
<style scoped lang="scss">
.bottom-right-1{
  width: 100%;
  height: 27%;
  .box-top{
    height: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3%;
  }
  .box-bottom{
    height: 50%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
</style>
