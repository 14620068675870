import { render, staticRenderFns } from "./xidiItem.vue?vue&type=template&id=03ec3092&scoped=true&"
import script from "./xidiItem.vue?vue&type=script&lang=js&"
export * from "./xidiItem.vue?vue&type=script&lang=js&"
import style0 from "./xidiItem.vue?vue&type=style&index=0&id=03ec3092&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03ec3092",
  null
  
)

export default component.exports