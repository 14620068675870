<template>
  <div class="bottom-center-1">
    <div class="num-view">
      <Number label="今日服务工单" unit="单" :value="totalValue"></Number>
      <Number label="累计服务工单" unit="单" :value="$padding4(totalValue1, 6)"></Number>
    </div>
    <div class="baifenbi">
      <template v-for="(res, index) in list">
        <div class="item" :key="index">
          <span class="label">{{res.label}}</span>
          <span class="progress">
            <span class="progress-line" :style="{width: res.value + '%'}"></span>
          </span>
          <span class="value">{{res.value}}%</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Number from "./number";
import {mapState} from "vuex";
export default {
  name: 'BottomCenter1',
  components: {Number},
  computed: {
    ...mapState({
      data: function(state) {
        return state.user.data
      }
    }),
    totalValue() {
      let data = this.$store.getters.data
      if(data.total){
        return data.total.today_count
      }
      return 0;
    },
    totalValue1() {
      let data = this.$store.getters.data
      if(data.total){
        return data.total.count
      }
      return 0
    },
  },
  watch: {
    data(newValue) {
      if (newValue) {
        let data = newValue
        let list = JSON.parse(JSON.stringify(this.list))
        list[0].value = data.jindu.green
        list[1].value = data.jindu.baojie
        this.list = list
      }
    }
  },
  data(){
    return {
      list: [
        {
          label: '今日绿化',
          value: '0'
        },
        {
          label: '今日保洁',
          value: '0'
        },
      ]
    }
  }
}
</script>
<style scoped lang="scss">
.bottom-center-1{
  .num-view{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
  }
  .baifenbi{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item{
      width: 48%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #4380F0;
      font-weight: bold;
      font-size: 24px;
      .label{
        width: 30%;
      }
      .progress{
        width: 40%;
        height: 12px;
        border-radius: 6px;
        background: rgba(255, 255,255,0.2);
        display: flex;
        flex: 1;
        margin-left: 10px;
        margin-right: 10px;
        .progress-line{
          height: 12px;
          border-radius: 6px;
          display: inline-block;
          background: linear-gradient(-90deg, #57E2FF, #299FFF, #299FFF);
        }
      }
      .value{
        width: 20%;
      }
    }
  }
}
</style>
