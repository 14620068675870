<template>
  <div class="bottom-left-1">
    <BorderBoxView1 title="保安当班人员">
      <dv-scroll-board :config="configValue" />
    </BorderBoxView1>
  </div>
</template>

<script>
import BorderBoxView1 from "./border/borderBoxView1";
import {assign} from "xe-utils";
export default {
  name: 'BottomLeft1',
  components: {BorderBoxView1},
  computed: {
      configValue() {
        let data = this.$store.getters.data
        let config = assign(true, this.config)
        if(data.safer){
          config.data = []
          data.safer.list.map((res, key) => {
            config.data.push([this.$padding4((key + 1).toString(), 2), res.name, res.staff_name,res.created_at])
          })
        }
        return config
      },
  },
  data() {
    return {
      config: {
        header: ['序号', '姓名', '汇报', '服务时间'],
        data: [],
        align: ['center','center','center','center'],
        headerBGC: 'rgba(241,241,241,0.06)',
        oddRowBGC: 'rgba(241,241,241,0.06)',
        evenRowBGC: 'rgba(241,241,241,0)',
        rowNum: 2
      }
    }
  },
}
</script>
<style scoped lang="scss">
.bottom-left-1{
  width: 100%;
  height: 15%;
}
</style>
