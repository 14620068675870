<template>
  <div class="header">
    <span class="time">{{nowtime}}</span>
    <span class="text">{{name}}</span>
    <div class="tianqi">
      <v-mini-weather color="#2ebcdd"></v-mini-weather>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment'
moment.locale('zh-cn');
export default {
  name: 'Header',
  computed: {
    ...mapState({
      name: state => state.user.name
    })
  },
  data(){
    return {
      nowtime: moment().format('LLLL')
    }
  },
  mounted() {
    setInterval(() => {
      this.nowtime = moment().format('YYYY-MM-DD HH:mm:ss dddd')
    },1000)
  }
}
</script>
<style scoped lang="scss">
.header{
  width: 100%;
  height: 113px;
  line-height: 113px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: url("../assets/head.png");
  background-size: 100% 100%;
  background-position: center center;
  .text{
    background-image:-webkit-linear-gradient(bottom,#1b4cc2,#05dcf8);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    font-size: 40px;
    font-weight: 700;
    color: #fff;
    flex: 1;
    width: 60%;
  }
  .time{
    color: #2ebcdd;
    font-size: 14px;
    width: 20%;
    text-align: left;
    margin-left: 35px;
  }
  .tianqi{
    width: 20%;
    color: #2ebcdd;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 35px;
  }
}
</style>
