<template>
  <div class="bottom-left-4">
    <BorderBoxView1 title="保洁服务">
      <div class="box-top">
        <XidiItem2 :list="xidiValue1"></XidiItem2>
        <XidiItem2 :list="xidiValue2"></XidiItem2>
      </div>
      <div class="box-bottom">
        <dv-scroll-board :config="configValue" />
      </div>
    </BorderBoxView1>
  </div>
</template>

<script>
import BorderBoxView1 from "./border/borderBoxView1";
import XidiItem2 from "./xidiItem2";
import {assign} from "xe-utils";
import {mapState} from "vuex";
export default {
  name: 'BottomLeft4',
  components: {BorderBoxView1, XidiItem2},
  computed: {
    configValue() {
      let data = this.$store.getters.data
      let config = assign(true, this.config)
      if(data.baojie){
        config.data = []
        data.baojie.list.map((res, key) => {
          config.data.push([this.$padding4((key + 1).toString(), 2), res.name, res.address,res.created_at])
        })
      }
      return config
    },
    ...mapState({
      data: function(state) {
        return state.user.data
      }
    }),
  },
  watch:{
    data (newValue){
      if(newValue){
        let data = newValue
        if(data.clear){
          let xidiValue1 = JSON.parse(JSON.stringify(this.xidiValue1))
          let xidiValue2 = JSON.parse(JSON.stringify(this.xidiValue2))

          xidiValue1[0].value = parseInt(data.baojie.today_count)
          xidiValue2[0].value = parseInt(data.baojie.count)

          this.xidiValue1 = xidiValue1
          this.xidiValue2 = xidiValue2
        }
      }
    }
  },
  data() {
    return {
      config: {
        header: ['序号', '保洁人员', '服务位置', '服务时间'],
        data: [],
        align: ['center','center','center','center'],
        headerBGC: 'rgba(241,241,241,0.06)',
        oddRowBGC: 'rgba(241,241,241,0.06)',
        evenRowBGC: 'rgba(241,241,241,0)',
        rowNum: 2
      },
      xidiValue1: [
        {
          label: '今日保洁订单',
          value: '23单',
          valueColor: '#DCAD71',
          icon: require('../assets/icon0.png')
        }
      ],
      xidiValue2: [
        {
          label: '累计保洁订单',
          value: '1054单',
          valueColor: '#DCAD71',
          icon: require('../assets/icon0.png')
        }
      ],
    }
  }
}
</script>
<style scoped lang="scss">
.bottom-left-4{
  width: 100%;
  height: 27%;
  margin-top: 10%;
  .box-top{
    height: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3%;
  }
  .box-bottom{
    height: 50%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
</style>
