<template>
  <div class="bottom-left-2">
    <BorderBoxView1 title="洗涤/消杀工作数据">
      <div class="box-top">
        <XidiItem :list="xidiValue1"></XidiItem>
        <XidiItem :list="xidiValue2"></XidiItem>
        <XidiItem :list="xidiValue3"></XidiItem>
      </div>
      <div class="box-bottom">
        <dv-scroll-board :config="config" />
      </div>
    </BorderBoxView1>
  </div>
</template>

<script>
import BorderBoxView1 from "./border/borderBoxView1";
import XidiItem from "./xidiItem";
import {mapState} from 'vuex'
import {assign} from 'xe-utils'
export default {
  name: 'BottomLeft2',
  components: {XidiItem, BorderBoxView1},
  computed: {
    ...mapState({
      data: function(state) {
        return state.user.data
      }
    }),
  },
  watch:{
    data (newValue){
      if(newValue){
        let data = newValue
        if(data.clear){
          let config = assign(true, this.config)
          config.data = []
          data.clear.list.map((res, key) => {
            config.data.push([this.$padding4((key + 1).toString(), 2), res.address, res.xiaosha_goods, res.receive_num, res.created_at])
          })
          this.config = config
          let xidiValue1 = JSON.parse(JSON.stringify(this.xidiValue1))
          let xidiValue2 = JSON.parse(JSON.stringify(this.xidiValue2))
          let xidiValue3 = JSON.parse(JSON.stringify(this.xidiValue3))
          xidiValue1[0].value = parseInt(data.clear.today_clear_num)
          xidiValue1[1].value = parseInt(data.clear.today_xiaosha_num)

          xidiValue2[0].value = parseInt(data.clear.bad_num)
          xidiValue2[1].value = parseInt(data.clear.xiaosha_bad_num)

          xidiValue3[0].value = parseInt(data.clear.clear_num)
          xidiValue3[1].value = parseInt(data.clear.xiaosha_num)

          this.xidiValue1 = xidiValue1
          this.xidiValue2 = xidiValue2
          this.xidiValue3 = xidiValue3
        }
      }
    }
  },
  data() {
    return {
      config: {
        header: ['序号', '服务位置', '物品名称', '数量', '服务时间'],
        data: [],
        align: ['center','center','center','center','center'],
        headerBGC: 'rgba(241,241,241,0.06)',
        oddRowBGC: 'rgba(241,241,241,0.06)',
        evenRowBGC: 'rgba(241,241,241,0)',
        rowNum: 3
      },
      xidiValue1: [
        {
          label: '今日洗涤数量',
          value: 0,
          unit: '件',
          valueColor: '#CA72E7'
        },
        {
          label: '今日消杀数量',
          value: 0,
          unit: '件',
          valueColor: '#CA72E7'
        }
      ],
      xidiValue2: [
        {
          label: '洗涤损坏数量',
          value: 0,
          unit: '件',
          valueColor: '#DCAD71'
        },
        {
          label: '消杀损坏数量',
          value: 0,
          unit: '件',
          valueColor: '#DCAD71'
        }
      ],
      xidiValue3: [
        {
          label: '洗涤累计',
          value: 0,
          unit: '件',
          valueColor: '#CA72E7'
        },
        {
          label: '消杀累计',
          value: 0,
          unit: '件',
          valueColor: '#CA72E7'
        }
      ]
    }
  },
  mounted() {

  }
}
</script>
<style scoped lang="scss">
.bottom-left-2{
  width: 100%;
  height: 27%;
  margin-top: 10%;
  .box-top{
    height: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3%;
  }
  .box-bottom{
    height: 50%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
</style>
