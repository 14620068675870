<template>
  <div class="bottom-view">
    <div class="left">
      <BottomLeft1 />
      <BottomLeft2 />
      <BottomLeft3 />
      <BottomLeft4 />
    </div>
    <div class="center" ref="bottomCenter">
      <BottomCenter1 />
      <Map v-if="mapWidth !== ''" :scrollerHeight="mapHeight" :scroller-width="mapWidth" />
    </div>
    <div class="right">
      <BottomRight1 />
      <BottomRight2 />
      <BottomRight3 />
    </div>
    <div class="bottom-bg"></div>
  </div>
</template>

<script>
import BottomLeft1 from "./bottomLeft1";
import BottomLeft2 from "./bottomLeft2";
import BottomLeft3 from "./bottomLeft3";
import BottomLeft4 from "./bottomLeft4";
import BottomRight1 from "./bottomRight1";
import BottomRight3 from "./bottomRight3";
import BottomRight2 from "./bottomRight2";
import Map from "./map";
import BottomCenter1 from "./bottomCenter1";
export default {
  name: 'Bottom',
  components: {
    BottomCenter1,
    Map, BottomRight2, BottomRight3, BottomRight1, BottomLeft4, BottomLeft3, BottomLeft1, BottomLeft2 },
  data() {
    return {
      mapWidth: '',
      mapHeight: '',
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.mapWidth = this.$refs['bottomCenter'].offsetWidth+`px`;
      this.mapHeight = (this.$refs['bottomCenter'].offsetWidth * 0.9)+`px`;
    })
  }
}
</script>
<style scoped  lang="scss">
.bottom-view{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: relative;
  .left{
    width: 25%;
    height: 100%;
    z-index: 4;
    position: relative;
  }
  .center{
    width: 45%;
    height: 100%;
    z-index: 1;
  }
  .right{
    width: 25%;
    height: 100%;
    z-index: 4;
    position: relative;
  }
  .bottom-bg{
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 1061px;
    background: url("../assets/page_bg2.png") no-repeat;
    background-size: 100% 100%;
    background-position: center 250px;
    bottom: 0;
    left: 0;
  }
}
</style>
