<template>
  <div class="bottom-left-3">
    <BorderBoxView1 title="绿化服务">
      <dv-scroll-board :config="configValue" />
    </BorderBoxView1>
  </div>
</template>

<script>
import BorderBoxView1 from "./border/borderBoxView1";
import {assign} from "xe-utils";
export default {
  name: 'BottomLeft3',
  components: {BorderBoxView1},
  computed: {
    configValue() {
      let data = this.$store.getters.data
      let config = assign(true, this.config)
      if(data.green) {
        config.data = []
        data.green.list.map((res, key) => {
          config.data.push([this.$padding4((key + 1).toString(), 2), res.address, res.name, res.content, res.created_at])
        })
      }
      return config
    },
  },
  data() {
    return {
      config: {
        header: ['序号', '服务位置', '汇报人', '服务内容', '时间'],
        data: [],
        align: ['center','center','center','center'],
        headerBGC: 'rgba(241,241,241,0.06)',
        oddRowBGC: 'rgba(241,241,241,0.06)',
        evenRowBGC: 'rgba(241,241,241,0)',
        rowNum: 2
      }
    }
  }
}
</script>
<style scoped lang="scss">
.bottom-left-3{
  width: 100%;
  height: 15%;
  margin-top: 10%;
}
</style>
