<template>
  <div class="xidi-item2">
    <template v-for="(res, index) in list">
      <div class="item" :key="index">
        <div class="icon">
          <img :src="res.icon" />
        </div>
        <div class="info">
          <span class="value" :style="{color: res.valueColor}">{{res.value}}</span>
          <span>{{res.label}}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'XidiItem2',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style scoped lang="scss">
.xidi-item2{
  height: 80px;
  color: #fff;
  background: rgba(10,32,67,0.2);
  border: 2px solid rgba(36,81,192,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40%;
  padding: 0 2%;
  font-size: 12px;
  box-shadow: inset 0px 0px 5px 5px rgba(36, 81, 192, 0.3000);
  .item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .icon{
      width: 36px;
      height: 30px;
      margin-right: 20px;
    }
    .info{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      .value{
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
}
</style>
