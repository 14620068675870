<template>
  <div class="xidi-item">
    <template v-for="(res, index) in list">
      <div :key="index" class="item" :class="index === 0 ? `first` : ``">
        <span class="label">{{res.label}}</span>
        <dv-digital-flop class="value" :config="{number: [res.value], content: '{nt}'+res.unit, style: {fontSize: 14, fill: res.valueColor}}" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'XidiItem',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  }
}
</script>
<style scoped lang="scss">
.xidi-item{
  height: 65px;
  color: #fff;
  background: rgba(10,32,67,0.2);
  border: 2px solid rgba(36,81,192,0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 27%;
  padding: 0 2%;
  font-size: 10px;
  box-shadow: inset 0px 0px 5px 5px rgba(36, 81, 192, 0.3000);
  .item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
  }
  .value{
    flex: 1;
    margin-top: 3px;
  }
  .first{
    border-bottom: 1px dashed #34DCFC;
  }
}
</style>
