<template>
  <div class="number">
      <span>{{label}}</span>
      <div>
        <template v-for="(res, index) in value.toString().split('')">
          <span class="num" :key="index">{{res}}</span>
        </template>
      </div>
      <span>{{unit}}</span>
  </div>
</template>

<script>
export default {
  name: 'Number',
  props: {
    label: {
      type: String,
      default: () => ''
    },
    unit: {
      type: String,
      default: () => ''
    },
    value: {
      type: [Number, String],
      default: () => ''
    }
  }
}
</script>
<style scoped lang="scss">
.number{
  color: #4380F0;
  font-size: 24px;
  font-weight: 900;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .num{
    color: #01FFF6;
    background: linear-gradient( #07256A, rgba(7,37,106,0));
    border: 1px solid;
    border-image: linear-gradient(0deg, rgba(0,180,255,0.8), rgba(0,180,255,0)) 1 1;
    border-radius: 5px;
    margin: 0 5px;
    padding: 5px 8px;
    overflow: hidden;
  }
}
</style>
