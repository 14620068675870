<template>
  <div class="border-box-view-1" :ref="ref">
    <svg
        :width="newWidth" :height="newHeight"
        class="border"
    >
      <line x1="0" y1="30" x2="0" :y2="newHeight" stroke="#34dcfc" fill="transparent" stroke-width="4"/>
      <line x1="212" y1="0" :x2="newWidth" :y2="0" stroke="#34dcfc" fill="transparent" stroke-width="4"/>
      <line :x1="newWidth" :y1="0" :x2="newWidth" :y2="newHeight-40" stroke="#34dcfc" fill="transparent" stroke-width="4"/>
      <line :x1="0" :y1="newHeight" :x2="newWidth - 108" :y2="newHeight" stroke="#34dcfc" fill="transparent" stroke-width="4"/>
      <rect :x="newWidth - 88" :y="newHeight - 11" width="10" height="10" stroke="#34dcfc" fill="transparent" stroke-width="2"/>
      <rect :x="newWidth - 66" :y="newHeight - 11" width="2" height="10" stroke="#34dcfc" fill="transparent" stroke-width="2"/>
      <rect :x="newWidth - 54" :y="newHeight - 11" width="52" height="10" stroke="#34dcfc" fill="transparent" stroke-width="2"/>
      <polygon points="184,1 197,1 189,8 176,8" stroke="#34dcfc" fill="transparent" stroke-width="2"/>
      <polygon points="207,1 242,1 235,8 199,8" stroke="#34dcfc" fill="transparent" stroke-width="2"/>
    </svg>
    <div class="border-box-content">
      <div class="title">
        <slot name="title">{{title}}</slot>
      </div>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import autoResize from '@jiaminghi/data-view/src/mixin/autoResize'
import { deepMerge } from '@jiaminghi/charts/src/util/index'
import { deepClone } from '@jiaminghi/c-render/lib/plugin/util'

export default {
  name: 'BorderBoxView1',
  mixins: [autoResize],
  computed: {
    newWidth: function() {
      return this.width - 4
    },
    newHeight: function() {
      return this.height - 4
    }
  },
  props: {
    color: {
      type: Array,
      default: () => ([])
    },
    backgroundColor: {
      type: String,
      default: 'transparent'
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      ref: 'border-box-1',

      border: ['left-top', 'right-top', 'left-bottom', 'right-bottom'],

      defaultColor: ['#4fd2dd', '#235fa7'],

      mergedColor: []
    }
  },
  watch: {
    color () {
      const { mergeColor } = this

      mergeColor()
    }
  },
  methods: {
    mergeColor () {
      const { color, defaultColor } = this

      this.mergedColor = deepMerge(deepClone(defaultColor, true), color || [])
    }
  },
  mounted () {
    const { mergeColor } = this

    mergeColor()
  }
}
</script>

<style lang="scss">
.border-box-view-1 {
  position: relative;
  background: linear-gradient(45deg, rgba(66,134,231, 0.3), rgba(54,103,236, 0.1), rgba(66,134,231, 0.3)) ;
  width: 100%;
  height: 100%;
  padding: 2px;

  .border {
    position: absolute;
    display: block;
  }

  .title{
    color: #34dcfc;
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    top: -6px;
    left: 0;
  }
  .content{
    position: absolute;
    left: 20px;
    top: 25px;
    right: 20px;
    bottom: 20px;
  }
}
</style>
