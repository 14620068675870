<template>
  <div class="bottom-right-2">
    <BorderBoxView1 title="配送服务">
      <div class="box-top">
        <div class="box-top-item">
          <dv-charts :option="option" />
          <span class="unit">(单)</span>
          <span class="label">今日配送任务工单</span>
        </div>
        <div class="box-top-item">
          <dv-charts :option="option1" />
          <span class="unit">(单)</span>
          <span class="label">累计配送任务工单</span>
        </div>
      </div>
      <div class="box-bottom">
        <dv-scroll-board :config="config" />
      </div>
    </BorderBoxView1>
  </div>
</template>

<script>
import BorderBoxView1 from "./border/borderBoxView1";
import {mapState} from "vuex";
import {assign} from "xe-utils";
export default {
  name: 'BottomRight2',
  components: {BorderBoxView1},
  computed: {
    ...mapState({
      data: function(state) {
        return state.user.data
      }
    }),
  },
  watch:{
    data (newValue){
      if(newValue){
        let data = newValue
        if(data.send){
          let config = assign(true, this.config)
          let option = assign(true, this.option)
          let option1 = assign(true, this.option1)
          config.data = []
          data.send.list.map((res, key) => {
            config.data.push([this.$padding4((key + 1).toString(), 2), res.name, res.mate_name, res.mate_num, res.created_at])
          })
          this.config = config
          option.series[0].data[0].value = data.send.today_count
          option1.series[0].data[0].value = data.send.count
          this.option = option
          this.option1 = option1
        }
      }
    }
  },
  data() {
    return {
      option: {
        series: [
          {
            type: 'gauge',
            data: [ { name: 'itemA', value: 0, gradient: ['#2B2CB4', '#FC4031', '#FC4031'] } ],
            center: ['50%', '55%'],
            splitNumber: 12,
            animationCurve: 'easeInOutBack',
            pointer: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
            },
            details: {
              show: true,
              offset: [0, 0],
              formatter: '{value}',
              style: {
                fill: '#FC4031',
                fontSize: 25
              }
            },
            backgroundArc: {
              style: {
                stroke: '#2B2CB4'
              }
            }
          }
        ]
      },
      option1: {
        series: [
          {
            type: 'gauge',
            data: [ { name: 'itemA', value: 0, gradient: ['#2B2CB4','#FA6DFC','#FA6DFC'] } ],
            center: ['50%', '55%'],
            splitNumber: 12,
            animationCurve: 'easeInOutBack',
            pointer: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
            },
            details: {
              show: true,
              offset: [0, 0],
              formatter: '{value}',
              style: {
                fill: '#FA6DFC',
                fontSize: 25
              }
            },
            backgroundArc: {
              style: {
                stroke: '#2B2CB4'
              }
            }
          }
        ]
      },
      config: {
        header: ['序号', '配送人员', '物资名称', '数量', '服务时间'],
        data: [
          ['01', '罗永英', '病人检查', '1', '06:10'],
          ['02', '尚秀秦', '拿药', '3', '08:30'],
        ],
        align: ['center','center','center','center','center'],
        headerBGC: 'rgba(241,241,241,0.06)',
        oddRowBGC: 'rgba(241,241,241,0.06)',
        evenRowBGC: 'rgba(241,241,241,0)',
        rowNum: 2
      }
    }
  }
}
</script>
<style scoped lang="scss">
.bottom-right-2{
  width: 100%;
  height: 34%;
  margin-top: 10%;
  .box-top{
    height: 55%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 3%;
    .box-top-item{
      height: 100%;
      width: 45%;
      position: relative;
      .unit{
        color: #00CAFF;
        position: absolute;
        left: 50%;
        top: 70%;
        transform: translateX(-50%);
        font-size: 14px;
      }
      .label{
        color: #00CAFF;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 90%;
        font-size: 14px;
        background: rgba(8,9,126,0.49);
        border: 1px solid #103286;
        padding: 4px 10px;
        white-space: nowrap;
        border-radius: 100px;
      }
    }
  }
  .box-bottom{
    height: 30%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
}
</style>
